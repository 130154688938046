export const sortTypes = ['score', 'date'] as const;

export const searchEngine = ['auto', 'keyword_search', 'english', 'multilingual'] as const;

export const searchEngineNames = {
  auto: 'Automatic',
  keyword_search: 'English-based keyword search',
  english: 'English-based semantic search',
  multilingual: 'Multilingual semantic search'
};

export const factCheckRequestLimit = '100';
