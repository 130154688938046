export const logEventSearchIds = (data, search_engine, query = '') => {
  const ids = data.map((obj) => obj.id);
  const parsedIds = {};
  if (ids.length % 10 === 0) {
    for (let i = 0; i < ids.length; i = i + 10) {
      parsedIds[`ids_${i}_${i + 10}`] = ids.slice(i, i + 10);
    }
  } else {
    const numGroups = Math.floor(ids.length / 10);
    for (let i = 0; i < numGroups * 10; i = i + 10) {
      parsedIds[`ids_${i}_${i + 10}`] = ids.slice(i, i + 10);
    }
    parsedIds[`ids_${numGroups * 10}_${numGroups * 10 + (ids.length % 10)}`] = ids.slice(
      numGroups * 10,
      numGroups * 10 + (ids.length % 10)
    );
  }
  // eslint-disable-next-line @typescript-eslint/dot-notation
  console.log(window['gtag'])
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'event_search_results', {
    search_engine: search_engine,
    query: query,
    ...parsedIds
  });
};

export const logEventClickId = (user_input, fact_check_id) => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'event_click_id', {
    user_input: user_input,
    fact_check_id: fact_check_id
  });
};

export const logPageChange = (change_page) => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  window['gtag']('event', 'event_change_page', {
    change_page: change_page
  });
};
