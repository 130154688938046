import { Filters, FiltersAction, SearchEngine } from 'src/types/Filters.d';
import { searchEngine } from './consts';

export const defaultFilters: Filters = {
  searchPhrase: '',
  langs: [],
  domains: [],
  startDate: null,
  endDate: null,
  searchEngine: searchEngine[0]
};

export const filtersReducer = (state: Filters, action: FiltersAction): Filters => {
  switch (action.type) {
    case 'reset':
      return { ...defaultFilters, searchPhrase: state.searchPhrase };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

export function dateSort(ascending) {
  return function (a, b) {
    if (a.published_at === b.published_at) {
      return 0;
    }
    if (a.published_at === '') {
      return 1;
    }
    if (b.published_at === '') {
      return -1;
    }
    if (ascending) {
      return a.published_at < b.published_at ? -1 : 1;
    }
    return a.published_at < b.published_at ? 1 : -1;
  };
}

export function scoreSort(ascending) {
  return function (a, b) {
    if (ascending) {
      return a.score - b.score;
    } else {
      return b.score - a.score;
    }
  };
}

export const getSearchEngineHint = (se: SearchEngine) => {
  switch (se) {
    case 'auto':
      return 'Automatic selection will analyze the provided input and according to its language and length will automatically select the most appropriate search engine.';
    case 'keyword_search':
      return 'A simple English-based keyword-based search (a standard/baseline engine that can provide good results for shorter inputs and can serve for comparison with previous more advanced models).';
    case 'english':
      return 'An English-based semantic search, where you can search any piece of text (a sentence, a paragraph or even a whole FB / Telegram / Twitter post) for any matching previously fact-checked claim. The input needs to be in English in this case (it can be translated by you from other languages using automatic translation such as Google Translate - if this model will perform well and we will use it in the production app, we will add automatic translation later).';
    case 'multilingual':
      return 'A Multilingual semantic search, which works the same as the English-based semantic search, but should be able to work with all common languages, including CEDMO ones, i.e.,  Slovak, Czech, and Polish.';
    default:
      return '';
  }
};
