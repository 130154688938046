import axios from 'axios';
import React, { ReactNode, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from 'react-multi-select-component';
import classNames from 'classnames';
import { logEventSearchIds } from '../utils/analytics';
import * as langsJSON from '../language_mapping.json';
import langsDB from '../language_db.json';
import domainsDB from '../sources.json';
import HoverInfo from './HoverInfo';
import { getSearchEngineHint } from 'src/utils/components';

import downIcon from '../images/down-icon.png';
import { useSearchParams } from 'react-router-dom';
import { factCheckRequestLimit } from 'src/utils/consts';

export default function SearchEngine({
  setData,
  setCurrentPage,
  loadingData,
  setLoadingData,
  filters,
  dispatchFilters,
  setLoadingError,
  firstSubmitDone,
  currentPage,
  initialLoad,
  sorting,
  setRetrievedBy
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = async (initLoad) => {
    firstSubmitDone.current = true;

    if (!filters.searchPhrase || loadingData) return;

    setData([]);
    setLoadingData(true);
    setLoadingError(false);

    if (!initLoad) {
      setCurrentPage(1);
    }

    const searchQuery = new URLSearchParams({
      text: filters.searchPhrase,
      search_method: filters.searchEngine
    });
    filters.langs.forEach((lang) => searchQuery.append('language', lang.value));
    filters.domains.forEach((d) => searchQuery.append('domain', d));
    if (!!filters.startDate)
      searchQuery.set('published_date_from', filters.startDate.toISOString().split('T')[0]);
    if (!!filters.endDate)
      searchQuery.set('published_date_to', filters.endDate.toISOString().split('T')[0]);

    const searchQueryToSet = new URLSearchParams(searchQuery);
    searchQueryToSet.set('sorting', sorting);
    searchQueryToSet.set('page', currentPage.toString());

    setSearchParams(searchQueryToSet);

    searchQuery.set('limit', factCheckRequestLimit);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}?${searchQuery}`);
      setData(response.data.fact_checks);
      logEventSearchIds(response.data.fact_checks, filters.searchEngine, filters.searchPhrase);
      setRetrievedBy(response.headers['x-search-engine']);
    } catch (error) {
      console.error(error);
      setLoadingError(true);
    } finally {
      setLoadingData(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(initialLoad.current);
    }
  };

  useEffect(() => {
    if (initialLoad.current && !!filters.searchPhrase) {
      handleSubmit(initialLoad.current);
    }
    initialLoad.current = false;
  }, [filters.searchPhrase]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!initialLoad.current && firstSubmitDone.current) {
      searchParams.set('page', currentPage.toString());
      setSearchParams(searchParams);
    }
  }, [currentPage]);

  const userInputClasses =
    'bg-white border border-sky-500 rounded-md w-full p-2 appearance-none focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-transparent placeholder:text-slate-400';

  const langOptions = useMemo(() => {
    const langOpts: { label: string; value: string }[] = [];
    for (const l in langsJSON) {
      const code = langsJSON[l]?.code;
      if (!!code && langsDB.includes(code)) {
        langOpts.push({
          label: `${langsJSON[l].name.split(';')[0].split(',')[0]} (${langsJSON[
            l
          ].code?.toUpperCase()})`,
          value: langsJSON[l].code
        });
      }
    }
    return langOpts;
  }, []);

  const domainsDBo = useMemo(() => domainsDB.sort((a, b) => a.name.localeCompare(b.name)), []);

  const Label = ({ children }: { children?: ReactNode }) => (
    <div className="flex items-center h-8">{children}</div>
  );

  return (
    <div>
      <div>
        <Label>
          Text input:{' '}
          <HoverInfo
            title={`Please remember, that provided text should be English for English-based semantic search and English-based keyword search.
                      For Multilingual semantic search, it can be any language. Thank you.`}
            type="info"
          />
        </Label>
        <textarea
          className={classNames(userInputClasses, 'h-24')}
          id="text"
          name="text"
          placeholder={
            filters.searchEngine === 'multilingual'
              ? 'Please remember, that input should be provided in English language'
              : 'Provide an input statement'
          }
          onKeyDown={onEnterPress}
          rows={7}
          value={filters.searchPhrase}
          onChange={(e) => dispatchFilters({ type: 'searchPhrase', payload: e.target.value })}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pt-2">
        <div>
          <Label>
            Search engine:{' '}
            <HoverInfo title={getSearchEngineHint(filters.searchEngine)} type="info" />
          </Label>
          <span className="relative inline-block w-full">
            <img src={downIcon} className="absolute opacity-40 h-2.5 my-auto inset-y-0 right-2.5" />
            <select
              className={classNames(userInputClasses, 'pr-8 text-ellipsis')}
              onChange={(event) => {
                dispatchFilters({ type: 'searchEngine', payload: event.target.value });
              }}
              value={filters.searchEngine}
            >
              <option value="auto">Automatic</option>
              <option value="multilingual">Multilingual semantic search</option>
              <option value="english">English-based semantic search</option>
              <option value="keyword_search">English-based keyword search</option>
            </select>
          </span>
        </div>

        <div>
          <Label>Date from:</Label>
          <DatePicker
            className={userInputClasses}
            dateFormat="yyyy/M/d"
            selected={filters.startDate}
            onChange={(date) => dispatchFilters({ type: 'startDate', payload: date })}
            placeholderText="Select a date"
          />
        </div>
        <div>
          <Label>Date to:</Label>
          <DatePicker
            className={userInputClasses}
            dateFormat="yyyy/M/d"
            selected={filters.endDate}
            onChange={(date) => dispatchFilters({ type: 'endDate', payload: date })}
            placeholderText="Select a date"
          />
        </div>
        <div>
          <Label>Languages:</Label>
          <MultiSelect
            value={filters.langs}
            options={langOptions}
            onChange={(langs) => dispatchFilters({ type: 'langs', payload: langs })}
            labelledBy="Languages"
            overrideStrings={{ selectSomeItems: 'Select languages' }}
            hasSelectAll={false}
            ArrowRenderer={({ expanded }) => (
              <img
                src={downIcon}
                alt="down"
                className={classNames('h-2.5 opacity-40 !transition-none select-none', {
                  '-scale-y-100': expanded
                })}
              />
            )}
          />
        </div>
        <div>
          <Label>Fact-checking organization:</Label>
          <MultiSelect
            value={filters.domains.map((domain) => ({ label: domain, value: domain }))}
            options={domainsDBo.map((domain) => ({ label: domain.name, value: domain.name }))}
            onChange={(d) =>
              dispatchFilters({ type: 'domains', payload: d.map((domain) => domain.value) })
            }
            labelledBy="Domains"
            overrideStrings={{ selectSomeItems: 'Select sources' }}
            hasSelectAll={false}
            ArrowRenderer={({ expanded }) => (
              <img
                src={downIcon}
                alt="down"
                className={classNames('h-2.5 opacity-40 !transition-none select-none', {
                  '-scale-y-100': expanded
                })}
              />
            )}
          />
        </div>
        <div>
          <Label />
          <button
            className="text-sky-800 p-2 bg-sky-100 rounded-md hover:bg-sky-300 transition border border-sky-500 sm:col-span-2 md:col-span-1 w-full"
            onClick={() => {
              dispatchFilters({ type: 'reset' });
            }}
          >
            Reset filters
          </button>
        </div>
        <div className="col-span-2">
          <Label />
          <button
            className="bg-sky-500 text-white p-2 rounded-md hover:bg-sky-700 transition disabled:text-sky-500 disabled:bg-sky-700 disabled:hover:bg-sky-700 w-full"
            onClick={() => handleSubmit(initialLoad.current)}
            disabled={!filters.searchPhrase || loadingData}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
