import React from 'react';
import { logEventClickId } from '../utils/analytics';
import HoverInfo from './HoverInfo';
import classNames from 'classnames';

import { useLangNames } from 'src/utils/hooks';
import { searchEngineNames } from 'src/utils/consts';

export const FactChecks = ({
  factChecks,
  userInput,
  loadingData,
  sorting,
  setSorting,
  retrievedBy
}) => {
  const langNames = useLangNames();

  const pulseClasses = 'animate-pulse bg-neutral-200 rounded-full';
  if (loadingData) {
    const PulseLine = ({ className = '' }) => (
      <li
        className={classNames(
          'bg-white rounded-md border border-slate-300 flex items-center p-5 gap-5',
          className
        )}
      >
        <div className={classNames(pulseClasses, 'h-24 w-24')} />
        <div className="flex-auto flex flex-col gap-2">
          <div className={classNames(pulseClasses, 'h-6 w-2/3')} />
          <div className={classNames(pulseClasses, 'h-6 w-4/5')} />
          <div className={classNames(pulseClasses, 'h-6 w-1/2')} />
        </div>
      </li>
    );
    return (
      <div className="py-8">
        <ul className="flex flex-col gap-4">
          <PulseLine />
          <PulseLine className="opacity-80" />
          <PulseLine className="opacity-60" />
          <PulseLine className="opacity-40" />
        </ul>
      </div>
    );
  }

  const Line = ({ title, children, sourceLang = 'en', hoverText = null, className = '' }) => {
    if (!children) return null;
    return (
      <div className={classNames('pb-2', className)}>
        <p className="text-xs">{title}</p>
        <div className="flex items-center gap-3">
          <span>{children}</span>
          {sourceLang !== 'en' && !!hoverText && (
            <HoverInfo title={hoverText} type="translation" className="shrink-0" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="py-8">
      {!!factChecks.length && (
        <div className="flex items-center pb-4 gap-2">
          <div>
            Sort by:{' '}
            <button
              value="date"
              onClick={() => setSorting('date')}
              className={classNames('text-sky-700', {
                'underline text-sky-900': sorting === 'date'
              })}
            >
              Date
            </button>{' '}
            /{' '}
            <button
              value="score"
              onClick={() => setSorting('score')}
              className={classNames('text-sky-700', {
                'underline text-sky-900': sorting === 'score'
              })}
            >
              Relevance
            </button>
          </div>
          <div className="flex-auto" />
          <div className="text-xs">
            Retrieved by: <span className="font-bold">{searchEngineNames[retrievedBy]}</span>
          </div>
        </div>
      )}
      <ul className="flex flex-col gap-4">
        {factChecks.map((factCheck) => (
          <li
            key={factCheck.id}
            className="bg-white rounded-md border border-slate-300 flex items-center p-5 gap-5 flex-col md:flex-row"
          >
            {!!factCheck.image_url ? (
              <img
                src={factCheck.image_url}
                className="w-28 h-28 rounded-full shrink-0 object-cover"
              />
            ) : (
              <div className="w-28 h-28 rounded-full bg-slate-100 shrink-0" />
            )}
            <div className="flex-auto">
              <Line
                title="Claim:"
                sourceLang={factCheck.source_language}
                hoverText={factCheck.claim}
              >
                {factCheck.claim_en}
              </Line>
              <Line
                title="Title:"
                sourceLang={factCheck.source_language}
                hoverText={factCheck.title}
              >
                {!!factCheck.title && (
                  <a
                    onClick={() => {
                      logEventClickId(userInput, factCheck.id);
                    }}
                    href={factCheck.url}
                    target="_blank"
                    className="text-sky-700 underline"
                  >
                    {factCheck.title_en}
                  </a>
                )}
              </Line>
              <Line title="Rating:" className="pb-0">
                {factCheck.rating}
              </Line>
            </div>
            <div className="text-center px-4 w-44 shrink-0">
              <a
                href={`//${factCheck.source_name}`}
                target="_blank"
                className="text-sky-700 underline text-ellipsis text-nowrap overflow-hidden max-w-full block"
              >
                {factCheck.source_name}
              </a>

              <p className="font-bold text-lg py-2">
                {!!factCheck.source_language && (
                  <>
                    {langNames[factCheck.source_language]} (
                    <span className="uppercase">{factCheck.source_language}</span>)
                  </>
                )}
              </p>
              {!!factCheck.published_at && (
                <>
                  <div className="text-xs">Published on:</div>
                  <div className="font-bold">{factCheck.published_at?.substring(0, 10)}</div>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FactChecks;
