import classNames from 'classnames';
import React from 'react';
import { logPageChange } from 'src/utils/analytics';

export const Pagination = ({ factChecksPerPage, totalFactChecks, setCurrentPage, currentPage }) => {
  const pageNumber = Math.ceil(totalFactChecks / factChecksPerPage);
  const pages = [...Array(pageNumber + 1).keys()].slice(1);

  if (pageNumber < 2) return null;

  return (
    <nav className="flex justify-center">
      <ul className="flex items-center bg-sky-500 rounded-md overflow-hidden gap-px border border-sky-500">
        {pages.map((number) => (
          <li key={number}>
            <button
              onClick={() => {
                setCurrentPage(number);
                logPageChange(number);
              }}
              className={classNames('w-8 h-8 text-sky-800 bg-sky-100 hover:bg-sky-300 transition', {
                'bg-sky-300': number === currentPage
              })}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
