import NavigationBar from './NavBar';

import kinit from '../images/kinit.svg';
import eu from '../images/eu.jpg';

const Page = ({ children, ...props }) => {
  const contentClasses = 'px-4 sm:px-6 py-8 lg:p-12 max-w-6xl m-auto';
  return (
    <div {...props}>
      <NavigationBar contentClasses={contentClasses} />
      <div className={contentClasses}>{children}</div>
      <div className="pb-12 text-center text-sky-700">
        <p>Copyright {new Date().getFullYear()}</p>
        <a href="https://kinit.sk/" target="_blank" className="underline">
          <img src={kinit} alt="KInIT" className="h-8 inline-block my-2" />
        </a>
      </div>
      <div className="flex justify-center px-4 sm:px-6">
        <div className="max-w-96 flex items-center gap-4 text-xs text-justify pb-12">
          <img src={eu} className="h-10" alt="EU" />
          <p>
            This tool was in part supported by{' '}
            <a href="https://cedmohub.eu/" target="_blank" className="underline">
              CEDMO
            </a>
            , a project funded by the European Union under Contract number: 2020-EU-IA-0267.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Page;
