import React, { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import FactChecks from '../components/FactChecks';
import Pagination from '../components/Pagination';
import SearchEngine from '../components/SearchEngine';
import { SearchEngine as SearchEngineT, SortTypes } from '../types/Filters';
import { FactCheck } from 'src/types/FactCheck';
import { dateSort, defaultFilters, filtersReducer, scoreSort } from 'src/utils/components';
import Page from 'src/components/Page';
import { useSearchParams } from 'react-router-dom';
import { useLangNames } from 'src/utils/hooks';
import { sortTypes, searchEngine } from 'src/utils/consts';

const Home = () => {
  const factChecksPerPage = 10;
  const [searchParams, setSearchParams] = useSearchParams();

  const pageFromUrl =
    Number(searchParams.get('page')) >= 1 && Number(searchParams.get('page')) <= 10
      ? Number(searchParams.get('page'))
      : 1;

  const [data, setData] = useState<FactCheck[]>([]);
  const [retrievedBy, setRetrievedBy] = useState<string>('');

  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const langNames = useLangNames();
  const [filters, dispatchFilters] = useReducer(filtersReducer, {
    searchPhrase: searchParams.get('text') || defaultFilters.searchPhrase,
    startDate: !!searchParams.get('published_date_from')
      ? new Date(searchParams.get('published_date_from') as string)
      : defaultFilters.startDate,
    endDate: !!searchParams.get('published_date_to')
      ? new Date(searchParams.get('published_date_to') as string)
      : defaultFilters.endDate,
    searchEngine: searchEngine.includes(searchParams.get('search_method') as SearchEngineT)
      ? (searchParams.get('search_method') as SearchEngineT)
      : defaultFilters.searchEngine,
    langs: searchParams
      .getAll('language')
      .map((lang) => ({ value: lang, label: `${langNames[lang]} (${lang.toUpperCase()})` })),
    domains: searchParams.getAll('domain')
  });
  const [sorting, setSorting] = useState<SortTypes>(
    sortTypes.includes(searchParams.get('sort') as SortTypes)
      ? (searchParams.get('sort') as SortTypes)
      : sortTypes[0]
  );

  const firstSubmitDone = useRef<boolean>(false);
  const initialLoad = useRef<boolean>(true);

  const sortedData = useMemo(() => {
    const result = [...data];
    if (sorting === 'score') {
      result.sort(scoreSort(false));
    } else if (sorting === 'date') {
      result.sort(dateSort(false));
    }
    return result;
  }, [sorting, data]);

  useEffect(() => {
    if (!initialLoad.current && firstSubmitDone.current) {
      searchParams.set('sorting', sorting);
      setSearchParams(searchParams);
    }
  }, [sorting]);

  const indexOfLastFactCheck = currentPage * factChecksPerPage;
  const indexOfFirstFactCheck = indexOfLastFactCheck - factChecksPerPage;
  const currentFactChecks = sortedData.slice(indexOfFirstFactCheck, indexOfLastFactCheck);
  console.log('error', loadingError);
  console.log('loading', loadingData);
  return (
    <Page>
      <SearchEngine
        setData={setData}
        setCurrentPage={setCurrentPage}
        loadingData={loadingData}
        setLoadingData={setLoadingData}
        filters={filters}
        dispatchFilters={dispatchFilters}
        setLoadingError={setLoadingError}
        firstSubmitDone={firstSubmitDone}
        currentPage={currentPage}
        initialLoad={initialLoad}
        sorting={sorting}
        setRetrievedBy={setRetrievedBy}
      />
      {!data.length && !loadingError && !loadingData && firstSubmitDone.current && (
        <div className="rounded-md px-6 py-4 bg-sky-200 mt-8 text-sky-700">
          No results found, try different filters.
        </div>
      )}
      {loadingError && !loadingData && (
        <div className="rounded-md px-6 py-4 bg-rose-200 mt-8 text-rose-700">
          Failed to load results, please try again later.
        </div>
      )}
      <FactChecks
        factChecks={currentFactChecks}
        userInput={filters.searchPhrase}
        loadingData={loadingData}
        sorting={sorting}
        setSorting={(s) => {
          setCurrentPage(1);
          setSorting(s);
        }}
        retrievedBy={retrievedBy}
      />
      {!loadingData && (
        <Pagination
          factChecksPerPage={factChecksPerPage}
          totalFactChecks={data.length}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </Page>
  );
};
export default Home;
