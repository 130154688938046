import cedmo from '../images/cedmo_logo.png';
import classNames from 'classnames';

function NavigationBar({ contentClasses }) {
  return (
    <nav className="bg-sky-500">
      <div className={classNames(contentClasses, 'lg:py-8')}>
        <div className="flex items-center gap-10">
          <a href="/search/q">
            <img src={cedmo} alt="logo" className="w-28 md:w-40" />
          </a>
          <a
            href="/search/q"
            className="text-white no-underline text-xl md:text-2xl cursor-pointer"
          >
            Fact-check finder
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
