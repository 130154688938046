import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import translateIcon from '../images/translate-icon.png';
import infoIcon from '../images/info-icon.png';

const HoverInfo = ({ title, type, ...props }) => {
  const returnIcon = () => {
    switch (type) {
      case 'translation':
        return (
          <img
            src={translateIcon}
            alt="Translate"
            className="h-6 w-6 select-none pointer-events-none"
          />
        );
      default:
        return (
          <img src={infoIcon} alt="Info" className="h-5 w-5 select-none pointer-events-none" />
        );
    }
  };

  return (
    <Tooltip
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.black'
          }
        }
      }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      {...props}
    >
      <div className="p-1.5 hover:bg-sky-100 rounded-full cursor-pointer transition">
        {returnIcon()}
      </div>
    </Tooltip>
  );
};

export default HoverInfo;
