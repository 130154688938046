import { useMemo } from 'react';

import * as langsJSON from '../language_mapping.json';

export const useLangNames = () => {
  return useMemo(() => {
    const langs = {};
    for (const l in langsJSON) {
      if (langsJSON[l]?.code) {
        langs[langsJSON[l].code] = langsJSON[l].name.split(';')[0].split(',')[0];
      }
    }
    return langs;
  }, []);
};
